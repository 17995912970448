"use client";
import { useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Image from "../image/Image.js";
import { useTranslation } from "react-i18next";
import "./Navbar.css";
import TabBar from "./Components/tabBar/TabBar.jsx";
import UserWhite from "../../../assets/Icons/user__white.jsx";
import MenuOutline from "../../../assets/Icons/menu__outline.jsx";
import SearchFilter from "../../cHome/hero/SearchFilter/SearchFilter.jsx";
import LoginModel from "../loginModel/LoginModel.jsx";
import InquiryModel from "../inquiryModel/InquiryModel.jsx";
import HotelPageDrop from "./Components/hotelPageDrop/HotelPageDrop.jsx";
import useHomePageDataManager from "../../../hooks/useHomePageDataManager.js";
import RoutesLink from "../../../constant/RoutesLink.js";
import LangIcon from "./../../langicon/LangIcon";
import DZText from "../../text/DZText.js";
import useDisableScroll from "../../../hooks/useDisableScroll.js";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../local_storage/index.js";
import { checkRegionExist, getLanguageFromDomain } from "../top-level-domain/index.js";
import { Config, KEYS, LANGUAGES_CODE, TLD } from "../../../constant/Index.js";
import ForgotPasswordModal from "../forgotPasswordModal/ForgotPasswordModal.jsx";
import { UserIcon } from "../../../assets/Icons/AllIcons.jsx";
import useOutsideClick from "../../../hooks/useOutsideClick.js";
import RegionIcon from "../../regionIcon/RegionIcon.js";

export default function Navbar() {

  const isRegionExist = checkRegionExist();
  const isSelectedRegionLanguage = getLocalStorage(KEYS.region_lang);

  if (!process.env.REACT_APP_DEVELOPMENT) {

    if (isRegionExist) {
      setLocalStorage(KEYS.region_lang, getLanguageFromDomain());
    } else {
      setLocalStorage(KEYS.region_lang, isSelectedRegionLanguage ?? LANGUAGES_CODE.English);
    }
  };

  if(process.env.REACT_APP_DEVELOPMENT) {
    setLocalStorage(KEYS.region_lang, isSelectedRegionLanguage ?? LANGUAGES_CODE.English);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { slug } = useParams();
  const location = useLocation();

  const pathname = location.pathname;
  const [loginModel, setLoginModel] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [openTabBar, setOpenTabBar] = useState(false);
  const [openHotelBar, setOpenHotelBar] = useState(false);
  const profileModalRef = useRef(null);
  const [showLanDrop, setShowLanDrop] = useState(false);
  const userDropRef = useRef(null);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [inquiryModel, setInquiryModel] = useState({
    first: false,
    second: false,
  });
  useDisableScroll(inquiryModel.first || inquiryModel.second);
  const { pages } = useHomePageDataManager();
  const handleLogout = () => {
    removeLocalStorage(Config.userToken);
    navigate(RoutesLink.HOME);
    // window.location.reload();
  };
  const handleLoginModel = () => {
    setLoginModel(!loginModel);
  };
  const handleInquiryModel = () => {
    setInquiryModel({ first: true, second: false });
  };
  const handleTabClose = () => {
    setOpenTabBar(!openTabBar);
  };
  const handleHotelTab = () => {
    setOpenHotelBar(!openHotelBar);
  };

  const PageUrls = () => {
    return (
      <div className="drop__down">
        {pages?.map((page, index) => (
          page?.is_active == 1 ?
            <Link to={page?.url} key={index} className={`drop__link ${slug === `${page?.url}` ? "active" : ""}`}>
              <DZText>{page?.title}</DZText>
            </Link>
            : <></>
        ))}
      </div>
    );
  };

  useOutsideClick(profileModalRef, () => setOpenProfileModal(false));

  const fnOnClickProfileTab = () => {
    setOpenProfileModal(false);
  };

  return (
    <>
      <InquiryModel
        inquiryModel={inquiryModel}
        setInquiryModel={setInquiryModel}
      />
  
      <LoginModel
        loginModel={loginModel}
        setLoginModel={setLoginModel}
        setForgotModal={setForgotModal}
      />
      {forgotModal && <ForgotPasswordModal setForgotModal={setForgotModal} />}
      <div className="nav__bar z-[2] section">
        <Link to="/" className="nav__logo">
          <Image src="/images/nav__logo.png" width={130} height={26} alt="" />
        </Link>

        <div className="flex items-center gap-6">

          { !isRegionExist && <LangIcon
            placeuse="icon__lang"
            setShowLanDrop={setShowLanDrop}
            showLanDrop={showLanDrop}
            userDropRef={userDropRef}
          />}

          { !isRegionExist && <RegionIcon />}

        </div>

        {pathname !== `${RoutesLink.HOTELS}` ? (
          <div className="nav__links">
            <Link
              to={RoutesLink.HOME}
              className={`link ${pathname === RoutesLink.HOME ? "active__link" : ""
                }`}
            >
              {t("navbar.front_page")}
            </Link>
            <Link
              to={RoutesLink.HOTELS}
              className={`link ${pathname === RoutesLink.HOTELS ? "active__link" : ""
                }`}
            >
              {t("navbar.hotels")}
            </Link>
            <Link
              to={RoutesLink.HOTELS}
              className={`link ${pathname === RoutesLink.HOTELS ? "active__link" : ""
                }`}
            >
              {"hotel test 1"}
            </Link>
            <Link
              to={RoutesLink.ABOUT}
              className={`link ${pathname === RoutesLink.ABOUT ? "active__link" : ""
                }`}
            >
              {t("navbar.about")}
            </Link>
            {pages?.length > 0 &&
              <div className="drop__link">
                <div className={`link `}>{t("navbar.info")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  className="link"
                >
                  <path
                    d="M1 1.5L5.29289 5.79289C5.62623 6.12623 5.79289 6.29289 6 6.29289C6.20711 6.29289 6.37377 6.12623 6.70711 5.79289L11 1.5"
                    stroke={"#333333"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <PageUrls />
              </div>
            }
          </div>
        ) : (
          <SearchFilter NavSearchFilter={true} />
        )}

        <div className="btn__container">
          <span className="link cursor__p" onClick={handleInquiryModel}>
            {t("navbar.get_an_offer")}
          </span>
          {
            <>
              {getLocalStorage(Config.userToken) ? (
                <div className=" relative">
                  <div
                    onClick={() => setOpenProfileModal(!openProfileModal)}
                    className="rounded-full py-[8px] px-[16px] cursor__p !bg-[#4A6DA7]"
                  >
                    <UserIcon iconColor={"white"} />
                  </div>
                  <div
                    className={`drop__down  ${openProfileModal ? "active !top-[150%]" : ""
                      } `}
                    ref={profileModalRef}
                  >
                    <Link
                      to={RoutesLink.LEADS_PAGE}
                      onClick={fnOnClickProfileTab}
                      className={`drop__link ${pathname === RoutesLink.LEADS_PAGE ? "acitve" : ""
                        } `}
                    >
                      {t("profile.my_leads")}
                    </Link>
                    <Link
                      to={RoutesLink.LEADS_OFFERS_PAGE}
                      onClick={fnOnClickProfileTab}
                      className={`drop__link ${pathname === RoutesLink.LEADS_OFFERS_PAGE
                        ? "acitve"
                        : ""
                        } `}
                    >
                      {t("profile.leads_offer")}
                    </Link>
                    <Link
                      to={RoutesLink.LEADS_CONTRACT_PAGE}
                      onClick={fnOnClickProfileTab}
                      className={`drop__link ${pathname === RoutesLink.LEADS_CONTRACT_PAGE
                        ? "acitve"
                        : ""
                        } `}
                    >
                      {t("profile.lead_offer_contract")}
                    </Link>

                    <Link
                      to={RoutesLink.MESSAGE_PAGE}
                      onClick={fnOnClickProfileTab}
                      className={`drop__link ${pathname === RoutesLink.MESSAGE_PAGE ? "acitve" : ""
                        } `}
                    >
                      {t("profile.messages")}
                    </Link>

                    <Link
                      to={RoutesLink.FAQS}
                      onClick={fnOnClickProfileTab}
                      className={`drop__link ${pathname === RoutesLink.FAQS ? "acitve" : ""
                        } `}
                    >
                      {t("profile.faqs")}
                    </Link>

                    <Link
                      to={RoutesLink.PROFILE_PAGE}
                      onClick={fnOnClickProfileTab}
                      className={`drop__link ${pathname === RoutesLink.PROFILE_PAGE ? "acitve" : ""
                        } `}
                    >
                      {t("profile.profile")}
                    </Link>

                    <span
                      onClick={() => handleLogout()}
                      className="cursor-pointer drop__link"
                    >
                      {t("navbar.log_out")}
                    </span>
                  </div>
                </div>
              ) : (
                <span className="btn cursor__p" onClick={handleLoginModel}>
                  <UserWhite />
                  {t("navbar.sign_in")}
                </span>
              )}
            </>
          }

          {pathname === `${RoutesLink.HOTELS}` && (
            <div className="cursor__p position__rel">
              <MenuOutline onClick={handleHotelTab} />
              {openHotelBar && (
                <HotelPageDrop
                  openHotelBar={openHotelBar}
                  setOpenHotelBar={setOpenHotelBar}
                />
              )}
            </div>
          )}
        </div>

        <div className="menu__icon cursor__p" onClick={handleTabClose}>
          <MenuOutline />
        </div>
      </div>
      {openTabBar && (
        <TabBar
          handleInquiryModel={handleInquiryModel}
          handleLoginModel={handleLoginModel}
          handleTabClose={handleTabClose}
          openTabBar={openTabBar}
          handleLogout={handleLogout}
          fnOnClickProfileTab={fnOnClickProfileTab}
          pathname={pathname}
        />
      )}
    </>
  );
}
