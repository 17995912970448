const RoutesLink = {
  HOME: "/",
  HOTELS: "/hotels",
  HOTELTEST: "/hotel-test",
  ABOUT: "/om-os",
  INFO: "/info",
  PRIVACY_POLICY: "/privatlivspolitik",
  TRADES_CONDITIONS: "/handelsbetingelser",
  REFERENCER: "/referencer",
  PAGE: "/:slug",
  HOTEL_DETAIL_USE: "/hotel-detail",
  HOTEL_DETAIL: "/hotel-detail/:slug",
  
  LEAD_OFFER: "/lead-offer/:slug",
  LEAD_CONTRACT: "/lead-contract/:slug",
  CONTRACT_PASSENGER: "/contract-passenger/:slug",
  SET_PASSWORD : "/set-password/:slug",

  LEADS_PAGE : '/leads',
  LEADS_OFFERS_PAGE  : '/leads-offers',
  LEADS_CONTRACT_PAGE  : '/leads-contract',
  MESSAGE_PAGE  : '/messages',
  PROFILE_PAGE  : '/profile',
  FAQS : '/faqs',

};
export default RoutesLink;
